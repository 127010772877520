<script setup lang="ts">
import type { IBreakdown } from "@/types";
import { BREAKDOWN_TYPE } from "@/constants";

export interface ModalProps {
  title?: string;
  description?: string;
  rootClass?: string;
}
const { t } = useI18n();
const toast = useToast();
const segment = useSegment();
const route = useRoute();
const props = defineProps<ModalProps>();
const emit = defineEmits(["open", "close", "cancel", "confirm"]);
const isAdvanced = ref(false);
const breakdownType = computed(() => (isAdvanced.value ? BREAKDOWN_TYPE.ADVANCED : BREAKDOWN_TYPE.BASIC));
const { state: breakdowns, isLoading } = useAsyncState(() => getBreakdowns(), [], { onError });
const filteredBreakdowns = computed(
  () => breakdowns.value?.filter((item: IBreakdown) => item.type === breakdownType.value),
);

function closeModal() {
  emit("close");
}

const { isLoading: isCheckoutStarted, execute: buyBreakDowns } = useAsyncState(
  (breakdown: IBreakdown) => {
    const payload = [{ priceId: breakdown.id, quantity: 1 }];

    return createCheckoutSession(payload).catch(() => toast.error(t("errors.checkout_session_creation_error")));
  },
  [],
  { immediate: false },
);

function onError(err: unknown) {
  toast.error(parseErrorMessage(err, { t }));
}

function handleBuyClick(breakdown: IBreakdown) {
  buyBreakDowns(0, breakdown);

  segment.track("Purchase Breakdown Requested", {
    source_of_intent_initiated: route.path,
    credit_type: breakdown.type,
    credit_package_count: breakdown.gamesQuantity,
  });
}

onMounted(() => {
  segment.track("Purchase Breakdown Credit Initiated", { source_of_intent_initiated: route.path });
});
</script>

<template>
  <UModal
    :model-value="true"
    dialog-class="!max-w-[900px]"
    @update:model-value="closeModal()"
  >
    <div class="p-4">
      <div class="text-end">
        <UButton
          title="Close"
          variant="link-secondary"
          icon="close"
          class="size-5"
          @click="closeModal()"
        />
      </div>

      <div class="flex flex-col md:px-6 md:pb-6">
        <div class="text-black dark:text-white">
          <h1
            class="text-center text-2xl font-bold"
            v-text="props.title"
          />
        </div>
        <div class="text-center text-black dark:text-white">
          <p
            v-if="props.description"
            class="mt-4 text-sm font-medium text-neutral-light-800"
          >
            {{ props.description }}
          </p>
        </div>
        <div class="text-center text-black dark:text-white">
          <p
            v-if="props.description"
            class="text-sm font-medium text-neutral-light-800"
          >
            {{ t("pages.breakdowns.purchase_modal.helper_text") }}
          </p>
        </div>
        <div class="flex items-center justify-center gap-4 py-6">
          <span
            :class="!isAdvanced ? 'text-black dark:text-white' : 'text-neutral-light-800'"
            class="font-medium"
          >
            {{ t("pages.breakdowns.basic_package") }}
          </span>
          <UToggle v-model="isAdvanced" />
          <span
            :class="isAdvanced ? 'text-black dark:text-white' : 'text-neutral-light-800'"
            class="font-medium"
          >
            {{ t("pages.breakdowns.advanced_package") }}
          </span>
        </div>
        <div
          v-if="isLoading"
          class="flex flex-wrap items-end justify-center"
        >
          <BreakdownsBuyCardSkeleton />
          <BreakdownsBuyCardSkeleton />
          <BreakdownsBuyCardSkeleton />
        </div>
        <div
          v-else
          class="flex flex-wrap items-end justify-center"
        >
          <BreakdownsBuyCard
            v-for="breakdown in filteredBreakdowns"
            :key="breakdown.id"
            :breakdown="breakdown"
            :disabled="isCheckoutStarted"
            @buy-click="handleBuyClick"
          />
        </div>
        <div
          class="breakdowns mt-8 flex w-full flex-col items-center justify-center bg-neutral-light-50 p-6 dark:bg-neutral-dark-700 dark:text-white"
        >
          <p class="text-center text-base font-bold uppercase">
            {{ t("pages.breakdowns.advantages_title") }}
          </p>
          <ul class="advantages-list mt-6 flex max-w-[700px] flex-wrap justify-between">
            <div
              v-for="n in 7"
              :key="n"
              class="mt-2 flex w-1/2 items-center gap-3"
            >
              <UIcon
                name="check"
                class="relative text-[9px] text-blue-500"
              />
              <li class="relative inline-block text-sm font-medium">
                {{ t(`pages.breakdowns.advantages_list.advantage${n}`) }}
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </UModal>
</template>
